export default {
  language_en: 'English',
  language_de: 'German',
  menu_group_content: 'Content',
  menu_group_search: 'Search',
  menu_group_channels: 'Channels',
  menu_group_expert: 'Expert Mode',
  menu_dashboard: 'Dashboard',
  menu_page_editor: 'Content Editor',
  menu_menu_editor: 'Menu Editor',
  menu_banners: 'Advertising Banner',
  menu_redirects: 'Redirects',
  menu_search_redirects: 'Search Redirects',
  menu_search_fields: 'Search Fields',
  menu_search_filters: 'Filter',
  menu_search_advertising: 'Advertising Space',
  menu_search_synonyms: 'Synonyms',
  menu_search_antonyms: 'Antonyms',
  menu_search_boosting: 'Boosting',
  menu_search_searchable_links: 'Searchable Links',
  menu_search_configuration: 'Configuration',
  menu_search_personalization: 'Personalization',
  menu_monitoring: 'Monitoring',
  menu_channels_feeds: 'Product Feeds',
  menu_channels_mapping: 'Category Mapping',
  menu_expert_component_editor: 'Component Editor',
  menu_expert_public_fields: 'Public Fields',
  menu_expert_data_inspector: 'Data Inspector',
  menu_expert_index_overview: 'Index Overview',
  menu_expert_index_management: 'Index Management',
  menu_category: 'Category',
  menu_recommendation: 'Recommendations',
  menu_bundle: 'Smart Bundles',
  menu_streams: 'Streams',
  menu_experiment: 'A/B Experiments',
  banner_type_search: 'Search Result',
  banner_type_category: 'Category Listing',
  banner_type_manufacturer: 'Manufacturer Listing',
  boostField_insert: 'New products',
  boostField_sold: 'Number of sales',
  boostField_basket: 'Frequently added to the shopping cart',
  boostField_view: 'Number of detail page views',
  boostField_revenue: 'Generated Revenue',
  boostField_rating: 'Good ratings',
  boostField_profit_margin: 'High margin',
  advertising_type_search: 'Search Result',
  advertising_type_category: 'Category Listing',
  advertising_type_manufacturer: 'Manufacturer Listing',
  product: 'Product',
  page: 'Landing Pages',
  url: 'Manual URL',
  manufacturer: 'Manufacturer',
  category: 'Category',
  publicFields: 'Public Fields',
  field: 'Field',
  detailPages: 'Detail pages',
  productLists: 'Product lists',
  productOnLandingPages: 'Products on landing pages',
  changed: 'Changed',
  experiment_type_boosting: 'Ranking Mix',
  experiment_type_ml: 'Personalization',
  experiment_type_frontend: 'Frontend',
  experiment_type_recommendation: 'Recommendation',
  experiment_nb_orders: 'Orders',
  experiment_nb_conversions: 'Bought Products',
  personalization_description1:
    'Data-driven decision making and machine learning have recently emerged as methods with high utility for many kinds of applications.',
  personalization_description2:
    'We integrate Machine Learning into our search application to provide user-specific search.',
  personalization_description3:
    'Data such as browser type, operating system, device type, time, season or location allow conclusions to be drawn about the user and his needs. So you can easily imagine that the owner of an iPhone in the morning at 10am in Berlin and the owner of a Windows 7 PC in the evening at 6pm in Lower Bavaria have completely different wishes when entering the same search term.',
  personalization_description4:
    'But the best thing about machine learning is not to use ideas or insider knowledge to categorize users, as this is purely based on the collected behavior of other users.',
  personalization_description5:
    'The prerequisite for this possibility is of course the collection of data. Only when statistically significant data has been collected and the values for the machine learning model have converged does it make sense to activate the user-specific search.',
  personalization_description6:
    'With this search it is now possible to offer visitors from different user groups a better tailored search experience and this in turn affects the conversion rate.',
  experiment_bounce_count: 'Bounces',
  experiment_nb_orders_revenue: 'Revenue',
  experiment_sum_visit_length: 'Average Visit Length',
  experiment_chart_nb_orders: 'Orders per visit',
  experiment_chart_nb_conversions: 'Bought Products per visit',
  experiment_chart_bounce_count: 'Bounce rate in %',
  experiment_chart_nb_orders_revenue: 'Revenue per visit in €',
  experiment_chart_sum_visit_length: 'Average Visit Length in seconds',
  Übersicht: 'Dashboard',
  Werbebanner: 'Advertising Banner',
  'Such-Redirects': 'Search Redirects',
  Konfiguration: 'Configuration',
  Suchfelder: 'Search Fields',
  Werbeplätze: 'Advertising Space',
  'Suchbare Links': 'Searchable Links',
  'Produkt-Feeds': 'Product Feeds',
  'Kategorie Mapping': 'Category Mapping',
  'Komponent Editor': 'Component Editor',
  'Öffentliche Felder': 'Public Fields',
  Indexverwaltung: 'Index Management',
  'A/B Experimente': 'A/B Experiments',
  'Choose field for Slot': (number) => `Choose field for Slot ${number}`,
  step1: '1. Install Makaira Connect plugin in your shop system',
  step2: '2. Configuration of the plugin',
  installation1:
    'Our Makaira Connect modules are open source and everyone is encouraged to get in touch with us on',
  installation2: 'Issues and Pull Requests are highly appreciated.',
  installation3:
    'We recommend to use composer to install our Makaira Connect module.',
  installation4: 'Current module (fully featured)',
  installation5: 'Legacy module (supported until 12/2021):',
  installation6:
    'The Shopware Plugin is still under development. You can already take a look:',
  dataInspectorDescription: `The Data Inspector allows you to perform searches directly against the Makaira API as well as display the products from a stream.
  Use the returned results as a preview or as a test whether, for example, your modifier works as desired.`,
  dataInspectorModeInfo:
    'The mode allows you to choose which documents are displayed in the Data Inspector. The search preview only shows you documents that you would also see if you searched the shop (autosuggest, search results page). The "Show all" option also shows you all documents which, for example, would not be visible due to their active status, the searchable flag or the deviating shop ID. You will then find the reason in a separate column.',
  PreviewGuide1:
    'Search for a product to display recommendations. Then you can change the settings of the recommendation and see how the result changes. ',
  PreviewGuide2: 'Select a product to display recommendations:',
  PreviewGuide3:
    'After creating the Recommendation please enter edit mode to configure and see the preview',
  bannerDescription:
    'Product placements, manufacturers and categories manually. This gives you full control over the top positions of your item lists. This allows you to realize paid placements, such as those usually used for advertising cost subsidies.',
  contactInformation:
    'If you want to use this feature please contact us via support@makaira.io',
  streamsDescription:
    'The Product-Stream in the Pro-Version brings a new dynamic into play. Automatically fill categories and landing pages with the currently matching products. Boost item groups to increase sales and select products for export.',
  productfeedsDescription:
    'Fast and dynamic data exports give you full control over your data. Whether Google-Feed, Excel-Export for product management or a quick check - with the Feed-Engine you are in the driving seat.',
  machine_learningDescription:
    'Thanks to Operational Intelligence, we can personalize your search results and item lists with high performance. What sounds cryptic can easily be shown using an example. Contact us for a demo and let us get the most out of your data together.',
  recommendationsDescription:
    'Whether top sellers, warehouse sales, customer favorites or upselling of similar products - direct your customers to the right items and increase your sales.',
  mecDescription:
    'Headless E-Commerce and full control over online marketing: With the modules "Page Builder" and "Menu Editor" you get full control over all content elements.\nTime-controlled and personalized, you deliver the best content for your customers in the best possible way. This is twice the performance of Makaira.',
  ab_testingDescription:
    'Find the best Makaira configurations for your shop through datadriven A/B-tests or tests different frontend implementations against each other. If you already use our Makaira tracking, you can start right away!',
  bundlesDescription:
    'Create a Smart Bundle to let your customers choose multiple products in a row that belong together or to create more revenue through cross- and upselling.',
  page_editorDescription:
    'Headless E-Commerce and full control over online marketing: With the modules "Page Builder" and "Menu Editor" you get full control over all content elements.\nTime-controlled and personalized, you deliver the best content for your customers in the best possible way. This is twice the performance of Makaira.',
  wrong_instance_redirect: (oldInstance, newInstance) =>
    `You don't have access on instance "${oldInstance}". You have been redirected to "${newInstance}".`,
  'Products for Slot': (slotPosition) => `Products for Slot ${slotPosition}`,
  delete_modal_title: (count) =>
    count === 1
      ? `Do you really want to delete this entry?`
      : `Do you really want to delete ${count} entries?`,
  revision_restored: (time) => `Revision from ${time} restored.`,
  condition_modal_header: (slot1, slot2) =>
    `Edit condition between Slot ${slot1} and Slot ${slot2}`,
  'Field for Slot': (number) => `Field for Slot ${number}`,
  'Choose the filter criteria for the products of Slot': (slotPosition) =>
    `Choose the filter criteria for the products of Slot ${slotPosition}.`,
  'Connect Slot X with …': (slotPosition) =>
    `Connect Slot ${slotPosition} with …`,
  'Index & Importer': 'Index & Importer',
  'Index for': (lang) => `Index for ${lang}`,
  'Build new index for all languages': 'Build new index for all languages',
  'one-time': 'manual',
  indexListDescription:
    'An "index" holds all your shop and Makaira data for the fast delivery to your frontend.\nThere is no Index available yet, please setup your Importer to automatically create your first index and keep your data in Makaira up to date.',
  importerDescription:
    'No importer configured yet.\nThe Importer loads automatically data from your shop system to make products, categeories, manufacturers etc. available in Makaira.',
  link_plural: 'Links',
  product_plural: 'Products',
  searchredirect_plural: 'Search-redirects',
  page_plural: 'Landing pages',
  category_plural: 'Categories',
  manufacturer_plural: 'Manufacturer',
  copy_verb: 'Copy',
  mapping_description: `You are about to paste components on a page that has more languages. There needs to be a translation in all languages available on the page. Please choose a template to begin translating. From the select box pick the language you want to use as a guide to aid with translation, this will copy the content of the origin into the language of your choice. Pick "Don't map" to keep the language empty.`,
  haveDetailPageCalls_error: (
    <>
      Ouh, this check failed. Please refer to our{' '}
      <a href="https://docs.makaira.io/docs/tracking">
        documentation to set up the tracking
      </a>{' '}
      properly.
    </>
  ),
  haveAbandonedCartCalls_error: (
    <>
      Ouh, this check failed. Please refer to our{' '}
      <a href="https://docs.makaira.io/docs/tracking">
        documentation to set up the tracking
      </a>{' '}
      properly.
    </>
  ),
  haveOrderCompletedCalls_error: (
    <>
      Ouh, this check failed. Please refer to our{' '}
      <a href="https://docs.makaira.io/docs/tracking">
        documentation to set up the tracking
      </a>{' '}
      properly.
    </>
  ),
  checkEansOnMatomoExistsInMakaira_error: (
    <>
      Ouh, this check failed. Please refer to our{' '}
      <a href="https://docs.makaira.io/docs/checking-the-tracking-function#usage-of-sku--ean-as-unique-match-between-tracking-and-makaira-data">
        documentation for matching SKU/EAN in tracking data
      </a>
      . <br />
      There are EANS tracked via the Makaira Tracking that do not match with
      your imported product data.
    </>
  ),
  checkTermAggregationOnGroupingFields_error: (
    <>
      Ouh, this check failed. Please refer to the{' '}
      <a href="https://docs.makaira.io/docs/personalization#grouping-fields-in-personalization">
        documentation for personalisation grouping fields
      </a>
      .
    </>
  ),
  'Alle Benutzer': 'All regions',
  'Rest der Welt': 'Other locations',
  language_at: 'German (Austria)',
  language_ch: 'German (Switzerland)',
  language_cz: 'Czech',
  language_dk: 'Danish',
  language_se: 'Swedish',
  ml_trial_description_1: (
    <>
      In order to see the feature in action we offer a <b>30 day</b> trial{' '}
      <b>free of charge</b>. Many of our customers experienced an{' '}
      <b>uplift in revenue between 3% and 15%</b>.
    </>
  ),
  trial_days_left: (days) =>
    days === 0 ? (
      <>
        <strong>Last day</strong> …
      </>
    ) : (
      <>
        You have <strong>{days} days</strong> left ...
      </>
    ),
  chart_no_data_des: (
    <>
      We are currently verifying that the A/B test works as expected.
      <br />
      The more traffic you have the faster we will get a verification. <br />
      Should this take longer than 5-10 minutes get in touch with us.
    </>
  ),
  best_variant: (effect = '', rate) =>
    `Best Variant is ${
      effect.startsWith('-') ? 'without' : 'with'
    } Makaira Personalization with ${effect.replace(
      '-',
      ''
    )}% more revenue with a significance of ${
      rate !== 'NOT_ENOUGH_VISITS' ? rate + '%' : '< 50%'
    }`,
  audit_logDescription:
    "With Audit Logs you can see every change that's made in Makaira. Who did change what at which time?",
  day_left: (days) => `${days === 1 ? 'one day' : days + ' days'} left`,
  image: 'Image',
  duplicate_modal_title: (count) =>
    count === 1
      ? `Do you want to duplicate this entry?`
      : `Do you want to duplicate ${count} entries?`,
  noStorefrontDescription: (
    <>
      You have no storefront yet, but in some simple steps you can get one.
      <br />
      Just contact us over{' '}
      <a href="mailto:support@makaira.io">support@makaira.io</a> and get your
      Makaira hosted Storefront.
    </>
  ),
  errorStorefrontDescription: (errorId) => (
    <>
      Your storefront settings cannot be loaded because of an issue. Please
      reload and try again.
      <br />
      If the issue is not clearing up please contact us over{' '}
      <a href="mailto:support@makaira.io">support@makaira.io</a>
      <br />
      Error Number {errorId}
    </>
  ),
  externalStorefrontDescription: (
    <>
      Your storefront is not hosted in our Makaira Infrastructure,
      <br />
      please check out the Storefront Settings in your hosters UI.
      <br />
      If you want to host your Storefront with us and have direct access to a
      fully integrated solution,
      <br />
      just contact us over{' '}
      <a href="mailto:support@makaira.io">support@makaira.io</a>
    </>
  ),
  innerStreamDescription: (
    <>
      product variants that are children of the <b>main product stream</b> above{' '}
      <br />
      <b>and match this filter stream</b> will be exported
    </>
  ),
  kpi_beta_label: (
    <>
      This is a beta feature which is only visible for <b>administrative</b>{' '}
      users! If there are questions regarding the accuracy of the numbers please
      get in touch with us and we will optimze them together.
    </>
  ),
  multiple_role_user: (
    <>
      Cannot change role of user that have multiple domains.{' '}
      <a href="https://docs.makaira.io" target="_blank" rel="noreferrer">
        Read more
      </a>
    </>
  ),
  rankingMix_insert: 'New',
  rankingMix_profit_margin: 'Profit margin',
  rankingMix_rating: 'Rating',
  rankingMix_revenue: 'Revenue',
  rankingMix_sold: 'Number of sales',
  rankingMix_view: 'View',
  rankingMix_basket: 'Basket',
  rankingMix_new: 'New',
  'ReasonExplain_Not enough data for ranking mix.':
    'Less than 50% of the products do have boosting data',
  'No ranking mix data.': 'Bad data quality',
  import_unsupport_language_warning: (languages = []) => (
    <>
      You want to import the language{' '}
      {languages.map((lang) => `"${lang.toUpperCase()}", `)} which is currently
      not supported by Makaira. Please get in touch with{' '}
      <a href="https://docs.makaira.io" target="_blank" rel="noreferrer">
        support@makaira.io
      </a>{' '}
      and the language will be added.
    </>
  ),
  language_gb: 'United Kingdom',
  downloadAnalyticsTooltip: 'Download a CSV with tracking analytics',
  draft_ab_status: 'draft',
  running_ab_status: 'running',
  scheduled_ab_status: 'scheduled',
  done_ab_status: 'done',
  ab_frontend_description: (
    <>
      Visit the{' '}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://docs.makaira.io/docs/ab-experiments#ab-testing-in-the-frontend"
      >
        documentation
      </a>{' '}
      to learn about the implementation of frontend tests.
    </>
  ),
  ab_personalization_description: (
    <>
      Visit the{' '}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://docs.makaira.io/docs/ab-experiments"
      >
        documentation
      </a>{' '}
      to learn more about the AB testing.
    </>
  ),
  nb_visits: 'Visits',
  nb_uniq_visitors: 'Unique visitors',
  bounce_count: 'Bounces',
  nb_conversions: 'Bought products',
  nb_orders: 'Orders',
  nb_orders_revenue: 'Revenue',
  sum_visit_length: 'Average visit length',
  activating: 'Activating index',
  average: 'per visit',
  detected_effect: 'Proven impact',
  significance_rate: 'Statistical significance',
  promo_document_heading: (type) => `Choose ${type} to be promoted`,
  promo_document_desc: (type) =>
    `Which ${type} should be advertised on the lists?`,
  Auswahl: 'Select',
  sectionSavedSuccess: 'Section saved!',
  sectionExists: (name) =>
    `There exists already a section with the name '${name}'! Please rename the section or delete the old on before saving.`,
  start: 'just started',
  force_merge: 'index optimization',
  update_routing: 'move index',
  update_replicas: 'activate index replication',
  set_alias: 'swap with active index',
  done: 'activated',
}
