import React, { useEffect } from 'react'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import ImporterStore from '../../stores/ImporterStore'
import t from '../../utils/translate'
import { IndexStore, UIStore } from '../../stores'
import PageTitle from '../../components/PageTitle'
import { Column } from '../../components/Table'
import ResourceTable from '../../components/ResourceTable'

import StatusColumn from './components/StatusColumn'
import DateColumn from './components/DateColumn'
import StatusLabelColumn from './components/StatusLabelColumn'
import ColumnWithTooltip from './components/ColumnWithTooltip'
import './indexDetail.styl'

let timeoutReload = null

function IndexDetail() {
  const { lang } = useParams()
  const {
    fetchScheduleStatus,
    fetchAll,
    getRunningStatus,
    fetchIndexStatus,
    indicies,
    replicatype,
  } = ImporterStore
  const { fetchIndices, getIndicesByLanguage, activateIndex, deleteIndex } =
    IndexStore
  const runningStatus = getRunningStatus(lang)

  useEffect(() => {
    fetchIndices()
    fetchScheduleStatus()
    fetchAll()
    fetchIndexStatus()
    moment.locale(UIStore.currentLanguage)

    return () => {
      clearTimeout(timeoutReload)
    }
    // eslint-disable-next-line
  }, [])

  const prepareIndexDetailData = () => {
    const indices = getIndicesByLanguage(lang)

    if (indices.length < 2 && !!runningStatus) {
      indices.push({
        state: 'passive',
      })
    }

    return indices
  }

  const handleActivateIndex = (name) => activateIndex(name)

  const handleDeleteIndex = (name) => deleteIndex(name)
  const getIndice = (record) =>
    indicies.find(
      (item) => item.language === record.language && item.state === record.state
    ) || {}

  return (
    <>
      <PageTitle prefix={t('You are editing')}>
        {t('Index for')(lang.toUpperCase())}
      </PageTitle>
      <div className="content-wrapper index-detail">
        <ResourceTable
          rowKey="name"
          store={ImporterStore}
          data={prepareIndexDetailData()}
          loading={
            ImporterStore.state === 'pending' || IndexStore.state === 'pending'
          }
        >
          <Column
            title=""
            dataIndex="state"
            width={50}
            render={(state, row) => <StatusColumn {...row} language={lang} />}
          />
          <Column
            width={150}
            title={t('Revision')}
            dataIndex="lastRevision"
            render={(data) => (
              <ColumnWithTooltip
                tooltip={t('highest revision number in Makaira index')}
                text={data}
              />
            )}
          />

          <Column width={150} title={t('Document')} dataIndex="cleanCount" />

          {replicatype === 'default' && (
            <Column
              width={140}
              title={t('Open Changes')}
              render={(val, row) => (
                <ColumnWithTooltip
                  tooltip={t(
                    'Count of open changes (documents) that still have to be imported into Makaira, to be in sync with your shop'
                  )}
                  text={getIndice(row).openChanges}
                />
              )}
            />
          )}

          <Column
            width={200}
            title={t('Created')}
            dataIndex="created"
            render={(data) => <DateColumn date={data?.date} />}
          />

          <Column
            title={t('Status')}
            dataIndex="status"
            className="mk-table-cell__has-button"
            render={(data, row) => (
              <StatusLabelColumn
                {...row}
                language={lang}
                onActive={handleActivateIndex}
                onDelete={handleDeleteIndex}
              />
            )}
          />
        </ResourceTable>
      </div>
    </>
  )
}

export default observer(IndexDetail)
