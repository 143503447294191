import React, { useMemo } from 'react'
import { toJS } from 'mobx'
import set from 'lodash/set'
import { Tooltip } from 'antd'

import TextInput from '../TextInput'
import Select from '../Select'
import DatePicker from '../DatePicker'
import debounce from '../../utils/debounce'
import t from '../../utils/translate'
import StreamSelect from '../StreamSelect'
import Icon from '../Icon'
import Button from '../Button'

const ResourceTableFilter = ({
  store,
  filters = [],
  filterOptions = {},
  filterParams,
}) => {
  const { showTotal } = filterOptions
  // checking if store and filterParams are set enable the usage
  // of this component without any store. This is be used in the
  // DataInspector and the StreamInspector
  const params = (store && filterParams && toJS(store[filterParams])) ?? {}

  const contentComponent = useMemo(
    () => ({
      search: ({ title, onChange, ...rest }) => (
        <TextInput
          rounded
          icon="magnifying-glass"
          label={title}
          onChange={(e) => onChange(e.target.value)}
          {...rest}
        />
      ),
      'single-select': Select,
      datepicker: DatePicker,
      'stream-select': StreamSelect,
      button: Button,
    }),
    []
  )

  const debounceChange = debounce(({ val, filter }) => {
    if (!filter.onChange) return

    let _val = val

    if (filter.dataFormat) {
      _val = filter.dataFormat(val)
    }

    filter.onChange(set(params, filter.name, _val))
  }, 500)

  if (filters.length === 0) {
    return null
  }

  return (
    <div className="resource-table__filter">
      <div className="resource-table__fields">
        {filters.map((filter, index) => {
          const {
            onChange,
            dataFormat,
            minWidth = '12rem',
            info,
            ...rest
          } = filter
          const Component = contentComponent[filter.type]

          return (
            <div className="resource-table__filter-wrapper" key={index}>
              <Component
                onChange={(val) => debounceChange({ val, filter })}
                {...rest}
                style={{ minWidth: minWidth }}
              />
              {info && (
                <Tooltip title={info}>
                  <Icon
                    className="resource-table__infor-icon"
                    symbol="circle-info__solid"
                    width={20}
                    height={20}
                  />
                </Tooltip>
              )}
            </div>
          )
        })}
      </div>

      {showTotal && (
        <div className="resource-table__total">
          <span>{t('Total')}:</span>&nbsp;{filterOptions.total}
        </div>
      )}
    </div>
  )
}

export default ResourceTableFilter
