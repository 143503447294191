import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'

import Collapse, { Panel } from '../../../components/Collapse'
import Select from '../../../components/Select'
import DnDElements from './DnDElements'
import SEOSettings from './SEOSettings'
import Button from '../../../components/Button'
import Text from '../../../components/Text'
import Icon from '../../../components/Icon'
import { t } from '../../../utils'
import { AppStore, EditorStore, UIStore } from '../../../stores'
import formatLanguageKey from '../../../utils/formatLanguageKey'
import ContextMenu from './ContextMenu/ContextMenu'
import ContentWidget from './ContentWidgets'

const StructureCollapse = ({ formikRef }) => {
  const {
    showAddElementModal,
    contentLanguage,
    setContentLanguage,
    pageToEdit,
  } = EditorStore

  const history = useHistory()

  const handleSetContentLanguage = (lang) => {
    setContentLanguage(lang)
    const pathnameToArr = history.location.pathname.split('/')
    pathnameToArr.pop()
    pathnameToArr.push(lang)
    history.replace(pathnameToArr.join('/'))
  }

  useEffect(() => {
    // action button should only be visible on hover of the components region
    const contentPanel = document.querySelector(
      '.page-editor__content-panel .rc-collapse-content'
    )
    const toggleShowButton = (isOpen) => () => {
      const actionButton = document.querySelector(
        '.page-editor__field-actions--page'
      )
      if (actionButton) {
        if (isOpen) {
          actionButton.classList.add('open')
        } else {
          actionButton.classList.remove('open')
        }
      }
    }
    if (contentPanel) {
      contentPanel.addEventListener('mousemove', toggleShowButton(true))
      contentPanel.addEventListener('mouseleave', toggleShowButton(false))
    }
  }, [])

  return (
    <Collapse
      type="arrow"
      accordion={false}
      level={0}
      noPadding
      defaultActiveKey={['1', '2']}
      className="page-editor__collapse"
    >
      <Panel
        className="page-editor__content-panel"
        showArrow
        key="1"
        header={
          <div className="page-editor__panel-header">
            <Text size="echo" weight="bold">
              {t('Content')}
            </Text>
            {UIStore.languages.length > 1 && (
              <Select
                value={contentLanguage}
                options={UIStore.sortedLanguages.map((lang) => ({
                  label: formatLanguageKey(lang),
                  value: lang,
                }))}
                onChange={handleSetContentLanguage}
              />
            )}
            <ContextMenu type="page" />
            {EditorStore.elementsWithAnimationPlaying.includes(
              pageToEdit.relatedDocumentId || pageToEdit.id
            ) && (
              <div className="page-editor__field-copied page-editor__field-copied--page">
                <Icon symbol="check" />
              </div>
            )}
          </div>
        }
      >
        <div>
          <div>
            <DnDElements />
          </div>
          <Button
            variant="tertiary"
            icon="plus"
            iconPosition="left"
            onClick={showAddElementModal}
            level={-1}
          >
            {t('Add a component')}
          </Button>
        </div>
      </Panel>
      {pageToEdit.type !== 'snippet' && (
        <Panel
          showArrow
          key="2"
          header={
            <div className="page-editor__panel-header">
              <Text size="echo" weight="bold">
                SEO
              </Text>
            </div>
          }
        >
          <SEOSettings formikRef={formikRef} />
        </Panel>
      )}
      {pageToEdit.type !== 'snippet' &&
        AppStore.externalContentWidgets.map((app) => (
          <Panel
            key={app.slug}
            showArrow
            className="page-editor__content-panel page-editor__content-panel--content-widget"
            header={
              <div className="page-editor__panel-header">
                <Text size="echo" weight="bold">
                  {app.title[UIStore.currentLanguage]}
                </Text>
              </div>
            }
          >
            <ContentWidget {...app} />
          </Panel>
        ))}
    </Collapse>
  )
}

export default observer(StructureCollapse)
