import { observer } from 'mobx-react-lite'
import { UserManagementStore } from '../../../stores'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'

import HeadingMain from '../../../components/Headings/HeadingMain'
import Text from '../../../components/Text'
import Button from '../../../components/Button'
import { createRedirectPath, t } from '../../../utils'
import { routes } from '../../../routing'
import ConfirmModal from '../../../components/Modal/ConfirmModal'

function DeleteUser({ user }) {
  const [open, setOpen] = useState(false)
  const history = useHistory()

  const hide = () => {
    setOpen(false)
  }

  const handleRemoveUser = async () => {
    hide()
    await UserManagementStore.delete([user.user_id])
    history.push(createRedirectPath(routes.userList.path))
  }

  if (!user?.user_id) return null
  return (
    <div className="delete-user-section">
      <HeadingMain>{t('Remove access')}</HeadingMain>
      <Text>
        {t('The user will no longer be able to access this account.')}
      </Text>
      <Button
        onClick={() => setOpen(true)}
        variant="secondary-blocker"
        icon="trash"
        iconPosition="left"
      >
        {t('Remove access')}
      </Button>
      <ConfirmModal
        onSubmit={handleRemoveUser}
        message={t("Do you want to remove this user's access?")}
        mask
        visible={open}
        onClose={() => setOpen(false)}
      />
    </div>
  )
}

export default observer(DeleteUser)
