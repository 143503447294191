import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import ResourceTable from '../../components/ResourceTable'
import { ArrowAction, Column } from '../../components/Table'
import Text from '../../components/Text'
import { AntonymStore, SynonymStore, UIStore } from '../../stores'
import { createRedirectPath, formatLanguageKey, t } from '../../utils'
import SearchPageTitle from '../SearchConfigurationV3/ImporterPageTitle'
import ActionLayerStore from '../../stores/ActionLayerStore'
import ActiveField from '../../components/ActiveField'

const List = () => {
  const history = useHistory()
  const location = useLocation()
  const type = location.pathname.split('/')[2]
  const isAntonyms = location.pathname.endsWith('antonyms')
  const [selectedRows, setSelectedRows] = useState([])
  const { languages } = UIStore

  useEffect(() => {
    if (isAntonyms) {
      AntonymStore.fetchAntonyms(true)
    } else {
      SynonymStore.fetchSynonyms(true)
    }
    // eslint-disable-next-line
  }, [isAntonyms])

  let dataMapped
  if (isAntonyms) {
    dataMapped = {
      store: AntonymStore,
      resourceName: 'antonyms',
      title: 'Antonyms',
      words: 'antonyms',
      singular: 'Antonym',
    }
  } else {
    dataMapped = {
      store: SynonymStore,
      resourceName: 'synonyms',
      title: 'Synonyms',
      words: 'synonyms',
      singular: 'Synonym',
    }
  }

  const showDeleteWarning = () => {
    ActionLayerStore.openActionLayer({
      header: t('delete_modal_title')(selectedRows.length),
      saveTitle: 'OK',
      onSave: handleDelete,
      onClose: () => {},
    })
  }

  const handleDelete = async () => {
    await dataMapped.store.delete(selectedRows)

    if (dataMapped.store.state !== 'error') {
      setSelectedRows([])
      ActionLayerStore.closeActionLayer()
    }
  }

  return (
    <>
      <SearchPageTitle />
      <div className="content-wrapper">
        <ResourceTable
          title={t(dataMapped.title)}
          store={dataMapped.store}
          resourceName={dataMapped.resourceName}
          filterParams="filter"
          filters={[
            {
              type: 'single-select',
              title: t('Languages'),
              placeholder: t('Select language'),
              name: 'language',
              onChange: dataMapped.store.setFilter,
              defaultValue: dataMapped.store.filter.language || '',
              options: [
                {
                  label: t('Show all'),
                  value: '',
                },
                ...languages.map((l) => ({
                  value: l,
                  label: formatLanguageKey(l),
                })),
              ],
            },
            {
              type: 'search',
              title: t('Search'),
              name: 'q',
              onChange: dataMapped.store.setFilter,
              defaultValue: dataMapped.store.filter.q,
            },
          ]}
          headerAction={{
            type: 'create',
            action: () => history.push(createRedirectPath(`/${type}/new`)),
          }}
          loading={dataMapped.store.state === 'pending'}
          bulkActions={[
            {
              title: t('Delete'),
              onExecute: () => showDeleteWarning(),
            },
          ]}
          rowSelection={{
            selectedRows,
            onSelectionChange: (newRows) => setSelectedRows(newRows),
          }}
          onRowClick={(record) =>
            history.push(
              createRedirectPath(`/${dataMapped.resourceName}/${record.id}`)
            )
          }
          emptyText={t(`No ${dataMapped.resourceName} found.`)}
          createAction={() => history.push(createRedirectPath(`/${type}/new`))}
          createActionText={t('Create ' + dataMapped.singular)}
        >
          <Column
            width={70}
            title={t('Active')}
            dataIndex="active"
            align="center"
            render={(value) => <ActiveField active={value} />}
          />
          <Column
            title={t('Language')}
            dataIndex="language"
            width={150}
            sorter={true}
          />
          <Column
            title={t('Name (ID)')}
            className="mk-table-cell--bold"
            dataIndex="name"
            sorter={true}
            render={(_, record) => (
              <Text weight="bold" element="div">
                {record.name}{' '}
                <span className="bundle__list-id">({record.id})</span>
              </Text>
            )}
          />
          <Column
            title={t('Words')}
            className="mk-table-cell--bold"
            render={(_, record) => (
              <Text weight="bold">{record[dataMapped.words]}</Text>
            )}
          />
          <Column width={50} render={() => <ArrowAction />} />
        </ResourceTable>
      </div>
    </>
  )
}

export default observer(List)
