import { observer } from 'mobx-react-lite'
import { drop, without } from 'lodash'
import { Popover } from 'antd'

import Icon from '../../../../components/Icon'
import Text from '../../../../components/Text'
import ShadowScroll from '../../../../components/ShadowScroll'

import UIStore from '../../../../stores/UIStore'
import EditorStore from '../../../../stores/PageEditor/EditorStore'

import t from '../../../../utils/translate'
import formatLanguageKey from '../../../../utils/formatLanguageKey'

// Maximum number of languages (excluding the currently selected one) before a second context menu
// will be shown instead of showing all available languages in the main context menu.
const MAX_MENU_ENTRIES = 4
const ALL_OTHER_LANGUAGE = 'all-others'

const ContentLanguageExtraMenu = ({ onMenuHide, languages = [], onSelect }) => {
  return (
    <div
      onClick={onMenuHide}
      className="page-editor__field-menu-action page-editor__content-language-extra-menu"
    >
      <ShadowScroll maxHeight="300px">
        {languages.map((language) => (
          <button
            key={language}
            onClick={() => {
              onMenuHide()
              onSelect(language)
            }}
          >
            <Text size="charlie">
              {language === ALL_OTHER_LANGUAGE
                ? t('All other languages')
                : formatLanguageKey(language)}
            </Text>
          </button>
        ))}
      </ShadowScroll>
    </div>
  )
}

const ContentLanguageCopySection = ({
  onMenuHide,
  visible,
  element,
  type = 'page',
  isCopyToOthers,
}) => {
  const { languages } = UIStore
  const currentlySelectedLanguage = EditorStore.contentLanguage
  let availableLanguages = without(languages, currentlySelectedLanguage)

  const isShownInExtraMenu =
    availableLanguages.length > MAX_MENU_ENTRIES ||
    (availableLanguages.length > 2 && isCopyToOthers)

  if (isCopyToOthers && isShownInExtraMenu) {
    availableLanguages.unshift(ALL_OTHER_LANGUAGE)
  }

  /**
   * Copy the content from the select language to the currently selected language.
   *
   * @param language {string} code of the language to copy from
   */
  const handleCopyContent = (language) => {
    const copyFunc =
      type === 'page'
        ? EditorStore.copyContentFromLanguageForPage
        : EditorStore.copyContentFromLanguageForElement

    if (isCopyToOthers) {
      if (language === ALL_OTHER_LANGUAGE) {
        drop(availableLanguages).forEach((lang) =>
          copyFunc(currentlySelectedLanguage, lang, element)
        )
      } else {
        copyFunc(currentlySelectedLanguage, language, element)
      }
    } else {
      copyFunc(language, currentlySelectedLanguage, element)
    }
  }

  if (isShownInExtraMenu) {
    return (
      <Popover
        visible={visible === false ? false : undefined}
        content={
          <ContentLanguageExtraMenu
            languages={availableLanguages}
            onMenuHide={onMenuHide}
            onSelect={handleCopyContent}
          />
        }
        placement="rightTop"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="page-editor__field-menu-item"
        >
          <Icon width="20px" height="15px" symbol="paste" />
          <span>
            {t(isCopyToOthers ? 'Copy content to…' : 'Insert content from…')}
          </span>
        </div>
      </Popover>
    )
  }

  return availableLanguages.map(
    (language) =>
      language !== ALL_OTHER_LANGUAGE && (
        <div
          key={language}
          onClick={() => handleCopyContent(language)}
          className="page-editor__field-menu-item"
        >
          <Icon width="20px" height="15px" symbol="language__solid" />
          <span>
            {t(isCopyToOthers ? 'Copy content to' : 'Insert content from')}{' '}
            {formatLanguageKey(language)}
          </span>
        </div>
      )
  )
}

export default observer(ContentLanguageCopySection)
