import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import LoadingScreen from '../../components/LoadingScreen'
import PageTitle from '../../components/PageTitle'
import Select from '../../components/Select'
import Switch from '../../components/Switch'
import TagsInput from '../../components/TagsInput'
import TextInput from '../../components/TextInput'
import Text from '../../components/Text'
import {
  ActionLayerStore,
  AntonymStore,
  SynonymStore,
  UIStore,
} from '../../stores'
import { createRedirectPath, formatLanguageKey, t } from '../../utils'

import './style.styl'

const Edit = () => {
  const { id } = useParams()
  const location = useLocation()
  const history = useHistory()
  const type = location.pathname.split('/')[2]
  const isAntonyms = type === 'antonyms'
  const isEditView = id !== 'new'
  const store = isAntonyms ? AntonymStore : SynonymStore
  const detail = store.detail
  const isDirty = store.isDirty
  const [searchType, setSearchType] = useState(type)
  const [searchTermLeft, setSearchTermLeft] = useState([])
  const [searchTermRight, setSearchTermRight] = useState([])

  useEffect(() => {
    const init = async () => {
      await store.fetchDetails(
        id,
        UIStore.enterpriseConfiguration.defaultLanguage
      )
      if (location.state?.searchTerm) {
        setSearchTermLeft([location.state.searchTerm])
        store.updateField(store.detail, type, location.state.searchTerm)
        store.updateField(store.detail, 'language', location.state.language)
      }
      if (id === 'new') return
      let searchTerm = store.detail[type] || ''
      const newSearchType = searchTerm.includes('=>') ? 'association' : type
      setSearchType(newSearchType)
      if (newSearchType === 'association') {
        setSearchTermLeft(searchTerm.split('=>')[0].split(','))
        setSearchTermRight(searchTerm.split('=>')[1].split(','))
      } else {
        setSearchTermLeft(searchTerm.split(','))
      }
    }
    init()
    // eslint-disable-next-line
  }, [])

  function handleClose() {
    store.setDirty(false)
    history.push(createRedirectPath('/' + type))
  }

  async function handleSave(isStay) {
    const newId = await store.updateOrCreate()

    // We need to redirect to the correct URL if the page was created
    if (newId && isStay) {
      history.push(createRedirectPath(`/${type}/${newId}`))
    }
    if (!isStay) {
      history.push(createRedirectPath(`/${type}`))
    }
  }

  useEffect(() => {
    if (isDirty) {
      ActionLayerStore.openActionLayer({
        onSave: handleSave,
        onSaveWithContinue: () => handleSave(true),
        onClose: handleClose,
        saveTitle: t('Save & back to list'),
        closeTitle: t('Abort & back to list'),
      })
    } else {
      ActionLayerStore.closeActionLayer()
    }

    return () => {
      ActionLayerStore.closeActionLayer()
    }
    // eslint-disable-next-line
  }, [isDirty])

  if (store.isFetchingDetail) return <LoadingScreen />

  return (
    <>
      <PageTitle
        prefix={isEditView ? t('You are editing') : t('You are creating')}
        metaInfo={
          isEditView && {
            timestamp: detail.changed?.date,
            timezone: detail.changed?.timezone,
            user: detail.user,
            id: detail.id,
          }
        }
        hiddenFields={['internal-title']}
      >
        {isEditView ? detail.name : t(`A new ${type}`)}
      </PageTitle>
      <div className="content-wrapper antonyms">
        <div className="antonyms__section">
          <Switch
            checked={detail.active}
            type="horizontal"
            title={t('Active')}
            onChange={(v) => store.updateField(detail, 'active', v)}
          />
          <TextInput
            label={t('Name')}
            value={detail.name}
            onChange={(e) => store.updateField(detail, 'name', e.target.value)}
          />
          <div className="antonyms__section-row">
            <Select
              translateLabel
              options={UIStore.languages.map((language) => ({
                value: language,
                label: formatLanguageKey(language),
              }))}
              flexible
              value={detail.language}
              dropdownMatchSelectWidth={false}
              onChange={(value) => store.updateField(detail, 'language', value)}
              title={t('Language')}
            />
            <Select
              translateLabel
              options={[
                {
                  label: isAntonyms ? 'Antonyms' : 'Synonyms',
                  value: type,
                },
                {
                  label: 'Association',
                  value: 'association',
                },
              ]}
              flexible
              value={searchType}
              dropdownMatchSelectWidth={false}
              onChange={(value) => {
                setSearchType(value)
                if (value !== 'association') {
                  store.updateField(detail, type, searchTermLeft.join(','))
                }
              }}
              title={t('Type')}
            />
          </div>
        </div>
        <Text size="echo" weight="bold" className="antonyms__search-term">
          {t('Search terms')}
        </Text>
        <div className="antonyms__tag-input-wrapper">
          <TagsInput
            value={searchTermLeft}
            tokenSeparators={[',', ';']}
            onChange={(value) => {
              setSearchTermLeft(value)
              store.updateField(
                detail,
                type,
                searchType === 'association'
                  ? [value.join(','), searchTermRight.join(',')].join('=>')
                  : value.join(',')
              )
            }}
            title={t(
              `Add all the words for this ${type}, separate them by using a comma or a semicolon`
            )}
          />
          {searchType === 'association' && (
            <TagsInput
              value={searchTermRight}
              tokenSeparators={[',', ';']}
              onChange={(value) => {
                setSearchTermRight(value)
                store.updateField(
                  detail,
                  type,
                  [searchTermLeft.join(','), value.join(',')].join('=>')
                )
              }}
              title={t(
                `Add all the words for this ${type}, separate them by using a comma or a semicolon`
              )}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default observer(Edit)
