import qs from 'qs'
import { omitBy } from 'lodash'

import buildPagination from '../buildPagination'
import buildSorter from '../buildSorter'
import buildFilter from '../buildFilter'
import { trimObj } from '../trimObj'

export default function buildQueryString(options = {}) {
  const { pagination, filter, sorter, params = {} } = options
  let parameters = params

  if (pagination) {
    parameters = {
      ...parameters,
      ...buildPagination(pagination),
    }
  }

  if (filter) {
    parameters = {
      ...parameters,
      ...buildFilter(filter),
    }
  }

  if (sorter) {
    parameters = {
      ...parameters,
      ...buildSorter(sorter),
    }
  }

  // remove empty string value
  parameters = omitBy(parameters, (prop) => prop === '')
  parameters = trimObj(parameters)

  return qs.stringify(parameters)
}
