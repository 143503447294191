import { Popover } from 'antd'
import { observer } from 'mobx-react-lite'

import ClipboardMenu from './ClipboardMenu'
import Icon from '../../../../components/Icon'
import ContentLanguageCopySection from './ContentLanguageCopySection'

import t from '../../../../utils/translate'

import EditorStore from '../../../../stores/PageEditor/EditorStore'

const PageContextMenu = ({ onMenuHide, visible }) => {
  const hasPageElements = EditorStore.elements.length > 0
  const isClipboardNotEmpty = EditorStore.clipboard.length > 0

  return (
    <div onClick={onMenuHide} className="page-editor__field-menu-action">
      {hasPageElements && (
        <div
          onClick={() => EditorStore.copyToClipboard()}
          className="page-editor__field-menu-item"
        >
          <Icon width="20px" height="15px" symbol="clipboard" />
          <span>{t('Copy all components')}</span>
        </div>
      )}

      {isClipboardNotEmpty && (
        <Popover
          visible={visible === false ? false : undefined}
          content={<ClipboardMenu onMenuHide={onMenuHide} />}
          placement="rightTop"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="page-editor__field-menu-item"
          >
            <Icon width="20px" height="15px" symbol="paste" />
            <span>{t('Paste')}</span>
          </div>
        </Popover>
      )}

      {hasPageElements && (
        <>
          <ContentLanguageCopySection
            type="page"
            visible={visible}
            onMenuHide={onMenuHide}
          />
          <ContentLanguageCopySection
            type="page"
            visible={visible}
            onMenuHide={onMenuHide}
            isCopyToOthers
          />
        </>
      )}
    </div>
  )
}

export default observer(PageContextMenu)
