import { observer } from 'mobx-react-lite'
import Modal from '../../components/Modal'
import { t } from '../../utils'
import TextInput from '../../components/TextInput'
import Button from '../../components/Button'
import { useState } from 'react'
import EmptyTable from '../../components/Table/EmptyTable'
import Text from '../../components/Text'
import { get } from 'lodash'
import api from '../../api'

function ErrorLookupModal(props) {
  const { visible, onClose } = props

  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState('')
  const [result, setResult] = useState(null)

  const onSearchError = (e) => {
    e.preventDefault()
    setLoading(true)
    setResult(null)
    api.common.fetchErrorLog(code).then((res) => {
      const errorMessage = get(res, 'data.messages.0.message')
      setLoading(false)
      setResult(errorMessage)
    })
  }

  return (
    <Modal visible={visible} onClose={onClose} header={t('Error lookup')} mask>
      <form onSubmit={onSearchError} className="importer-logs__error-lookup">
        <TextInput
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder={t('Enter error code')}
        />
        <Button loading={loading} type="submit">
          Search
        </Button>
      </form>
      {result !== null && !loading && (
        <div className="importer-logs__error-lookup-result">
          {!result && (
            <EmptyTable
              emptyContainerSize="extreme-small"
              emptyText={t('No message found')}
            />
          )}
          {result !== undefined && (
            <div className="importer-logs__error-lookup-message">
              <Text>Message:</Text>
              <Text variant="book" size="medium">
                {result}
              </Text>
            </div>
          )}
        </div>
      )}
    </Modal>
  )
}

export default observer(ErrorLookupModal)
