import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { toast } from 'react-toastify'
import ApexCharts from 'apexcharts'
import classNames from 'classnames'

import ContentWrapper from '../../components/ContentWrapper'
import Tab from '../../components/PageTitle/Tab'
import { routes } from '../../routing'
import { t } from '../../utils'
import Text from '../../components/Text'
import Select from '../../components/Select'
import Button from '../../components/Button'
import api from '../../api'
import LineChart from './LineChart'
import RadialChart from './RadialChart'
import DatePicker from '../../components/DatePicker'
import { UIStore } from '../../stores'

import './style.styl'

const CATEGORY_NAME = {
  page_editor: 'Page Editor',
  productfeeds: 'Feeds',
  recommendations: 'Recommendation',
  search: 'Search',
}

const Statistic = () => {
  const [type, setType] = useState('line')
  const [instance, setInstance] = useState('all')
  const [date, setDate] = useState(moment())
  const [data, setData] = useState({})

  const categoryLimit = UIStore.featuresInfo.map((feature) => ({
    name: CATEGORY_NAME[feature.name] || feature.name,
    requestLimit: feature.requestLimit === -1 ? null : feature.requestLimit,
  }))

  useEffect(() => {
    const regex = /^https?:\/\/([^.]+)\./
    const match = window.location.origin.match(regex)
    const customer = match && match.length > 0 ? match[1] : 'stage'
    const getRouteStatistic = async () => {
      try {
        const statisticData = await api.common.routeStatistics({
          params: {
            customer,
            instance: instance !== 'all' ? instance : '',
            startDate: date.startOf('month').format('YYYY-MM-DD'),
            endDate: date.endOf('month').format('YYYY-MM-DD'),
          },
        })
        setData(statisticData.data)
      } catch (error) {
        console.log(error)
      }
    }

    getRouteStatistic()
  }, [date, instance])

  const Chart = type === 'line' ? LineChart : RadialChart

  const saveImageFromURI = (uri, fileName) => {
    const link = document.createElement('a')
    link.href = uri
    link.setAttribute('download', fileName)
    link.style.display = 'none' // Hide the link
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleDownloadImage = () => {
    ApexCharts.exec(`statistic-${type}`, 'dataURI').then(({ imgURI }) => {
      saveImageFromURI(imgURI, `statistic-${type}.png`)
    })
  }

  const handleCopyImageToClipboard = () => {
    ApexCharts.exec(`statistic-${type}`, 'dataURI').then(({ imgURI }) => {
      copyImageToClipboard(imgURI)
    })
  }

  const copyImageToClipboard = (dataUri) => {
    // Create an image element
    var img = new Image()

    // Set the cross-origin property to anonymous to avoid CORS issues
    img.crossOrigin = 'Anonymous'

    // Set the image source to the provided data URI
    img.src = dataUri

    // Wait for the image to load
    img.onload = function () {
      // Create a canvas element
      var canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      // Get the canvas context
      var ctx = canvas.getContext('2d')

      // Draw the image onto the canvas
      ctx.drawImage(img, 0, 0)

      canvas.toBlob(function (blob) {
        var clipboardItem = new ClipboardItem({ 'image/png': blob })
        navigator.clipboard.write([clipboardItem]).then(
          function () {
            toast.success('Image copied')
          },
          function (error) {
            toast.error('Unable to copy image to clipboard: ', error)
          }
        )
      })
    }

    // Handle image loading errors
    img.onerror = function () {
      console.error('Error loading image:', dataUri)
    }
  }

  return (
    <ContentWrapper>
      <Tab
        tabData={[
          {
            route: routes.statistic,
            title: t('Request'),
          },
        ]}
      ></Tab>
      <div className="statistic">
        <div
          className={classNames(
            'statistic__chart',
            `statistic__chart--${type}`
          )}
        >
          <Chart data={data} categoryLimit={categoryLimit} />
        </div>
        <div className="statistic__settings">
          <Text size="delta" weight="bold">
            {t('Chart settings')}
          </Text>
          <Select
            options={[
              { label: t('All Instances'), value: 'all' },
              ...UIStore.instances.map((i) => ({ label: i, value: i })),
            ]}
            onChange={setInstance}
            fullWidth
            title={t('Instance')}
            value={instance}
          />
          <DatePicker
            onChange={setDate}
            title={t('Choose month')}
            picker="month"
            value={date}
          />
          <Select
            options={[
              { label: t('Line'), value: 'line' },
              { label: t('Radial'), value: 'radialBar' },
            ]}
            onChange={setType}
            fullWidth
            title={t('Diagram type')}
            value={type}
          />
          <Text size="delta" weight="bold">
            {t('For your presentation')}
          </Text>
          <Button onClick={handleDownloadImage}>
            {t('Download as a PNG')}
          </Button>
          <Button onClick={handleCopyImageToClipboard}>
            {t('Copy a PNG to the clipboard')}
          </Button>
        </div>
      </div>
    </ContentWrapper>
  )
}

export default observer(Statistic)
